import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    link: [],
    windowWidth: window.innerWidth,
    year: '',
    dataLoaded: 0,
    data: {},
    poems: [],
    maxims: [],
    discography: [],
    photos: {
      categories: []
    }
  },
  getters: {
    links: state => {
      return state.data.links
    },
    mobileNav: state => {
      return state.windowWidth < 1200 ? true : false
    },
    maximsThemes: state => {
      return state.data.maxims.themes
    },
    poemsThemes: state => {
      return state.data.poems.themes
    },
    photosCategories: state => {
      state.photos.categories.forEach(el => {
        el.show = false
      })
      return state.photos.categories
    }
  },
  mutations: {
    setWindowWidth: state => {
      state.windowWidth = window.innerWidth
    },
    setData: (state, payload) => {
      state.data = payload
    },
    setPoems: (state, payload) => {
      state.poems = payload
    },
    setMaxims: (state, payload) => {
      state.maxims = payload
    },
    setDiscography: (state, payload) => {
      state.discography = payload
    },
    setPhotos: (state, payload) => {
      state.photos = payload
    },
    setYear: (state, payload) => {
      state.year = payload
    },
    setDataLoaded: (state, payload) => {
      state.dataLoaded = state.dataLoaded + payload
    },
    setPhotosContainerVisibility: (state, payload) => {
      state.photos.categories[payload.index].show = payload.show
    }
  },
  actions: {
    getWindowWidth: context => {
      context.commit('setWindowWidth')
    },
    getItems: async (context, payload) => {
      const fileName = payload.fileName
      const itemsName = payload.itemsName
      let params = new URLSearchParams()
      params.append('fileName', fileName)
      await axios.post('/php/getitems.php', params)
      .then(res => {
        context.commit('set' + itemsName, res.data)
        context.commit('setDataLoaded', 1)
      })
      .catch(
        context.commit('set' + itemsName, '[]')
      )
    },
    getYear: async context => {
      await axios.post('/php/getyear.php')
      .then(res => {
        context.commit('setYear', res.data)
      })
      .catch(
        context.commit('setYear', 'error')
      )
    },
    getData: async context => {
      let data = await axios.get('/data.json')
      let poems = await axios.get('/wiersze.json')
      let maxims = await axios.get('/maksymy.json')
      let discography = await axios.get('/dyskografia.json')
      let photos = await axios.get('/zdjecia.json')
      context.commit('setData', data.data)
      context.commit('setPoems', poems.data)
      context.commit('setMaxims', maxims.data)
      context.commit('setDiscography', discography.data)
      context.commit('setPhotos', photos.data)
      context.commit('setDataLoaded', 5)
    },
    setPhotosContainerVisibility: (context, payload) => {
      context.commit('setPhotosContainerVisibility', payload)
    }
  },
  modules: {
  }
})
