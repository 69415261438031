<template>
  <header ref="section">
    <div class="main-photo-container"
      :style="{
        backgroundImage: 'url(' + photo + ')',
        backgroundPositionY: bgPosY
      }"
    ></div>
    <div class="main-photo-text frame frame-lg">
      <div>
        <p v-html="header"></p>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'FrameLG',
  props: ['posY', 'photo', 'header'],
  data: () => ({
    bgPosY: '0px'
  }),
  computed: {
    
  },
  methods: {
    checkFrame () {
      if(this.$refs.section) {
        if (window.innerWidth > 960) {
          this.setBgPos()
        }
      }
    },
    setBgPos () {
      this.bgPosY = (0 - ((Math.max(this.posY)) / 2)) + 'px'
    }
  },
  mounted () {
    if (this.staticPhoto) {
      this.showFrame()
    }
  },
  created () {
    window.addEventListener('scroll', this.checkFrame)
  }
}
</script>

<style scoped lang="scss">

.main-photo-container {
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    margin-bottom: 50px;
    @media screen and (min-width: 960px) {
        background-attachment: fixed;
        background-position: 0 0;
        height: 700px;
        margin-bottom: 0;
    }
}
.main-photo-text {
    display: block;
    width: 300px;
    height: 250px;
    margin: 0 auto;
    transform: translateX(25px);
    background-color: white;
    div {
        position: absolute;
        width: 250px;
        padding: 20px 20px;
        border: 1px solid #444;
        z-index: 1;
        p {
            padding: 10px 15px;
            background-color: white;
        }
        span {
            color: #EB7054;
        }
        &:before {
            content: '';
            position: absolute;
            width: 60%;
            height: 65%;
            transform: translate(-100%, -50%);
            border: 1px solid #444;
            z-index: -1;
        }
    }
    @media screen and (min-width: 600px) {
        width: 470px;
        div {
            width: 80%;
        }
    }
    @media screen and (min-width: 960px) {
        margin: -220px 0 50px 0;
        transform: translateX(0);
        div {
            bottom: 30px;
            left: 45px;
            &:before {
                width: 70%;
            }
        }
    }
    @media screen and (min-width: 1200px) {
        width: 42%;
        height: 280px;
        margin: -250px 0 50px 0;
        div {
            p {
                width: 390px;
                margin: 0 auto;
            }
        }
    }
}
.frame {
    font-weight: 300;
    &.frame-lg {
        font-family: 'Cormorant Garamond', serif;
        font-size: 2rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: center;
        @media screen and (min-width: 1200px) {
            font-size: 2.2rem;
        }
        @media screen and (min-width: 1400px) {
            font-size: 2.4rem;
        }
    }
}

</style>
