var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"section"},[(!_vm.staticPhoto)?_c('div',{staticClass:"photo",style:({
      backgroundImage: 'url(' + _vm.photo + ')',
      backgroundPositionY: _vm.bgPosY,
      height: _vm.settedPhotoHeight
    })}):_c('div',{staticClass:"photo-static",style:({
      backgroundImage: 'url(' + _vm.photo + ')',
      height: _vm.settedPhotoHeight
    })}),_c('div',{staticClass:"frame"},[_c('h1',[_vm._v(_vm._s(_vm.header))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.formattedText)}}),(_vm.button)?_c('router-link',{attrs:{"to":_vm.button.path}},[_c('span',[_vm._v(_vm._s(_vm.button.text))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }